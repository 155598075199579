@each $name, $size in $sizes {

  /* padding-top */
  .#{$prefix}p#{$delimiter1}t#{if($name != '', $delimiter2 + $name, '')} {
    padding-top: #{$size} !important;
  }
  /* padding-right */
  .#{$prefix}p#{$delimiter1}r#{if($name != '', $delimiter2 + $name, '')} {
    padding-right: #{$size} !important;
  }
  /* padding-bottom */
  .#{$prefix}p#{$delimiter1}b#{if($name != '', $delimiter2 + $name, '')} {
    padding-bottom: #{$size} !important;
  }
  /* padding-left */
  .#{$prefix}p#{$delimiter1}l#{if($name != '', $delimiter2 + $name, '')} {
    padding-left: #{$size} !important;
  }

  /* horizontal paddings */
  .#{$prefix}p#{$delimiter1}#{$h}#{if($name != '', $delimiter2 + $name, '')} {
    padding-left: #{$size} !important;
    padding-right: #{$size} !important;
  }
  /* vertical paddings */
  .#{$prefix}p#{$delimiter1}#{$v}#{if($name != '', $delimiter2 + $name, '')} {
    padding-top: #{$size} !important;
    padding-bottom: #{$size} !important;
  }
  /* all paddings */
  .#{$prefix}p#{$delimiter1}#{$a}#{if($name != '', $delimiter2 + $name, '')} {
    padding: #{$size} !important;
  }
}
