@charset "utf-8";

@import "materialize/components/mixins";
@import "materialize/components/color";
@import "materialize/components/variables";

body{
    color: #464c54;
    font-size: 18px;
    line-height: 1.6;
    font-family: 'Montserrat', sans-serif;
}

ul > li {
    list-style-type: disc;
}
h1, h2, h3, h4, h5, h6 {
    -webkit-font-smoothing: antialiased;
    //color: black;
    //font-weight: 700;
    font-family: 'Montserrat', sans-serif;;
}

h4, h5, h6 {
    font-weight: 700;
}


p + h1, p + h2, p + h3, p + h4, p + h5, p + h6 {
    margin-top: 32px;
}

strong {
    font-weight: bold;
}
.icon-block {
  padding: 0 15px;
}
.icon-block .material-icons {
	font-size: inherit;
}
h1.page-title {
    font-weight: 500;
    font-size: 2.3em;
    text-align: center;
    color: #29b6f6;
}
.col.grid-example {
    border: 1px solid #eee;
    margin: 7px 0;
    text-align: center;
    line-height: 50px;
    font-size: 28px;
    background-color: tomato;
    color: white;
    padding: 0;
}
.collection .collection-item.avatar {
    padding-right: 30px;
}
.last-post {
    padding: 10px 0 15px;
}
.last-post .collection .collection-item.avatar>.date-post {
    position: absolute;
    width: 54px;
    height: 54px;
    overflow: hidden;
    left: 10px;
    display: inline-block;
    padding: 16px 0;
    vertical-align: middle;
    background: #ad1457;
    color: #fff;
    text-align: center;
}
.txt-center {
    text-align: center;
}
.post-header h1.post-title {
    color: #29b6f6;
    font-size: 2.8em;
    font-weight: 800;
}
.post-header time {
    color: #cacaca;
    font-size: 0.9em;
}
p.rss-subscribe {
    margin: 0;
}
figcaption {
    display: block;
    background: lighten(#26547c, 60%);
    padding: 1em;
    min-height: 50px;
    box-sizing: border-box;
}
figure {
    margin: 0;
    &.responsive {
        max-width: 50%;
        max-height: auto;
    }

    p.step {
        margin-top: 0px;
        color: #868f9b;
    }

    & > a {
        line-height: 0px;
        display: block;
    }
}

hr {
    margin: 40px 0;
}
.sidenav {
    width: 238px;
    position: fixed;
    top: 64px;
    bottom: 0px;
    overflow-x: hidden;
    padding-top: 20px;
    background-color: #f9f9ff;
}

.sidenav2 {
    width: 238px;
    position: fixed;
    top: 300px;
    left: 0px;
    bottom: unset;
    overflow-x: hidden;
    padding-top: 20px;
}

.bgwrap {
    background: #f9f9ff;
    display: flex;
}

.sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    display: block;
}

.main {
    margin-left: 0px;
    padding: 0px 0px;
    background: white;
    margin-left: 238px;
    width: calc(100% - 238px);
}
@media only screen and (max-width: 993px) {
    .sidenav {
        display: none;
    }
    .main {
        margin-left: 0px;
        width: 100%;
    }
}
#partners {
    max-width: 80%;
    margin: auto;
    display: block;
}
.partners:after{
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.partners {
    margin: auto;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    text-align: center;
    border-radius: 5px;
    width: 100%;
    height: auto;
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle;
}
.partner-img {
    width: 100%;
    max-width: 300px;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    vertical-align: middle;
}
@media only screen and (max-width: 993px) {
    .partner-img {
        padding: 3px 5px;
    }
    .partners {
        padding: 0px;
    }
    .row {
        .col {
            padding: 0px;
            max-width: 100%;
        }
    }
}

.fancybox-caption {
    font-size: 18px;
}

:not(pre) > code, :not(pre) > kbd, :not(pre) > samp {
    font-family: 'Roboto Mono',monospace;
    color: #f0506e;
    white-space: nowrap;
    font-size: 0.8em;
}

.brand-logo {
    white-space: nowrap;

    i {
        font-size: 40px !important;
    }

    img {
        max-height: 48px;
        vertical-align: middle;
        margin-right: 8px
    }
}

@media (max-width: 600px) {
    nav {
        height: 40px;
        line-height: 40px;

        .button-collapse {
            height: 40px;
            margin: 0 10px;
        }

        i {
            height: 40px !important;
            line-height: 40px !important;
        }
    }

    .brand-logo {
        font-size: 20px !important;
        i {
            font-size: 24px !important;
        }

        img {
            max-height: 32px;
        }
    }
}

.alert {
    padding: 20px;
    background-color:#f6685e;
    color: white;
    transition: opacity 0.6s;
    margin-bottom: 15px;
}

.important {
    padding: 20px;
    background-color: #fff3cd;
    transition: opacity 0.6s;
    margin-bottom: 15px;
    display: block;
    padding-left: 50px;
    position: relative;
    min-height: 70px;

    &::before {
        content: '!';
        position: absolute;
        left: 0px;
        width: 50px;
        text-align: center;
        font-size: 30px;
        margin-top: -8px;
        font-weight: 800;
        color: #f0506e;
    }
}

.info {
    padding: 32px 10px 0px 10px;
    background-color: #dfebf5;
    border-left: 3px solid #ef476f;
    transition: opacity 0.6s;
    margin: 15px 0px !important;
    display: block;
    position: relative;
}

.side-nav li > a {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.side-nav {
    padding-left: 0px;
}

#galerie {
    max-width: 80%;
    margin: auto;
    display: block;
}

img.crisp {
    image-rendering: pixelated; /* Chrome */
    image-rendering: crisp-edges; /* Firefox */
}

.rouge-gutter {
    width: 1%;
    border-right: 1px solid #ddd;
    color: #999;
}

td.rouge-code  {
    padding-left: 12px !important;
}

.rouge-table {
    font-size: 13px;
}

.coding-container {
    font-size: 16px;

    h1 {
        font-size: 200%;
        border-bottom: 2px solid #ef476f;
        width: 70%;
        padding-bottom: 12px;
    }

    h4 {
        font-size: 130%;
        margin-top: 48px;
    }

    li {
        margin-bottom: 0.5em;
    }
}

.coding-container-narrow {
    max-width: 850px;

    margin-left: auto;
    margin-right: auto;
}

.recovery-container {
    h1 {
        font-size: 200%;
        border-bottom: 2px solid #ef476f;
        width: 70%;
        padding-bottom: 12px;
    }

    h4 {
        font-size: 130%;
        margin-top: 48px;
    }

    li {
        margin-bottom: 0.5em;
    }
}

.recovery-container-narrow {
    max-width: 1000px;

    margin-left: auto;
    margin-right: auto;
}

.con-guide {
    @media #{$large-and-up} {
        width: 100%;
        padding: 20px;
    }

    .row::not(.info) {
        margin-bottom: 0px;
        .col {
            margin-bottom: 20px;
        }
    }
}

.lead {
    font-size: 1.4rem;
    font-weight: 300;
    color: black;
}

.spoiler {
    border-left: 3px solid #f0506e;

    pre.highlight {
        border-top: 1px solid #ccc;
        margin-top: 0px;
        transition-duration: 200ms;
        transition-property: filter;
    }
}

.sidenavHighglight {
    background: lighten(#26547c, 60%);
}

.search-form {
    display: flex;
    align-items: center;
    justify-content: end;

    input {
        color: white;
        border-color: white !important;
        margin: 0px !important;
        max-width: 200px;

        @media only screen and (max-width: 1365px) {
            display: none;
        }
    }

    i {
        line-height: initial !important;
        height: unset !important;
    }

    button {
        padding: 0 12px;
    }
}
